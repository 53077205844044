import React from 'react';

import UserListDialog from '../../../../../pages/MessengerPage/ui/dialog/UserListDialog';
import { useUsersQuery } from '../../../../../store/redux/user/hooks/useUsersQuery';

interface IProps {
  closeModal: () => void;
  edit?: boolean;
  modalPayload?: any;
}

const UpdateChatModal: React.FC<IProps> = (props) => {
  const { closeModal } = props;
  const { data: user } = useUsersQuery();

  return (
    <UserListDialog
      onClose={closeModal}
      userData={user}
    />
  );
};

export default UpdateChatModal;
