import { ISale, ISalesHistoryData } from '../../../../store/redux/sales/sales.interface';
import { apiGet, apiPost, apiPostWithoutData, apiPut } from '../../interceptors';

export const SalesService = {
  async putSale(value: ISale) {
    const response = await apiPut('/sales', value);

    return response.data && response.data ? response.data : [];
  },

  async postSaleExpenses(value: ISale) {
    const response = await apiPost(`/sales/expenses`, value);

    return response.data && response.data ? response.data : [];
  },

  async getSaleProductByDate(date: string) {
    const response = await apiGet(`/sales/${date}`);

    return response.data ? response.data?.sales || [] : [];
  },
  async getTransactionsHistory(data: ISalesHistoryData) {
    const response = await apiPost('/sales/filter', data);

    return response.data ? response.data?.sales || [] : [];
  },
  async cancelSale(id: string) {
    const response = await apiPostWithoutData(`/cancel_sales/${id}`);
    return response.data;
  }
};
